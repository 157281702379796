import { withTranslation } from "react-i18next";
import { Label, Spinner } from "reactstrap";
import moment from "moment";
import useQsParams from "../../Hooks/QueryString";
import RangeDatePicker from "../RangeDatePicker";


function DateFilter({ item, t, isLoading = false }) {
  const { qs, setSearchParams, setSearchParamsAsObject } = useQsParams();
  const field = qs.get(item.field)?.split(",");
  let value = [];

  if (field?.length) {
    value = [
      moment.unix(field[0]).format("D/M/YY"),
      moment.unix(field[1]).format("D/M/YY"),
    ];
  }

  const labelClasses = {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: '38px',
    outline: '0 !important',
    position: 'relative',
    transition: 'all 100ms',
    backgroundColor: 'hsl(0, 0%, 95%)',
    borderColor: 'hsl(0, 0%, 90%)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
  };

  const formattedValue = value?.length === 2 
    ? `${value[0]} até ${value[1]}` 
    : t("select-date"); // Placeholder

  return (
    <div className="mb-4 filter-item">
      {item?.name && (
        <Label className="form-label text-muted text-uppercase fw-semibold mb-3">
          {item.name}
        </Label>
      )}


      {isLoading ? (
        <div className="form-label text-muted" style={labelClasses}>
          <span className="" style={{ filter: 'blur(1.6px)', marginLeft: '15px' }}>
            {formattedValue}
          </span>
          <button
            className="border-0 m-1 p-1 position-absolute end-0 top-0 bottom-0"
            type="button"
            disabled
          >
            <i className="ri-calendar-line text-muted" />
          </button>
        </div>
      ) : (
        <RangeDatePicker
          options={{ ...item?.options }}
          name={item.field}
          value={value}
          placeholder={t("select-date")}
          onClear={() => setSearchParams(item.field, "")}
          onChange={(v) => {
            if (v[0] && v[1]) {
              const d1 = moment(v[0]).unix();
              const d2 = moment(v[1]).unix();

              setSearchParamsAsObject({
                [item.field]: !d2 ? d1 : `${d1},${d2}`,
                page: 1,
              });
            }
          }}
        />
      )}
    </div>
  );
}

export default withTranslation()(DateFilter);
