import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Label } from "reactstrap";
import moment from "moment";
import useQsParams from "../../../Components/Hooks/QueryString";
import RangeDatePicker from "../../../Components/Common/RangeDatePicker";


function Date({ item, t, placeholder }) {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const field = qs.get(item.field)?.split(",");
  let value = [];

  if (field?.length) {
    value = [
      moment.unix(field[0]).format("D/M/YY"),
      moment.unix(field[1]).format("D/M/YY"),
    ];
  }

  useEffect(() => {
    if (!field?.length) {
      const today = moment().startOf('day');
      const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day');

      const startDate = thirtyDaysAgo.unix();
      const endDate = today.unix();

      setSearchParamsAsObject({
        [item.field]: `${startDate},${endDate}`,
        page: 1,
      });
    }
  }, [field, item.field, setSearchParamsAsObject]);

  return (
    <div className="mb-4 filter-item">
      {item?.name && (
        <Label className="form-label text-muted text-uppercase fw-semibold mb-3">
          {item.name}
        </Label>
      )}

      <RangeDatePicker
        options={{ ...item?.options }}
        name={item.field}
        value={value}
        placeholder={placeholder ?? t("select-date")}
        onClear={() => {
          if (field?.length) {
            setSearchParamsAsObject({
              [item.field]: "", 
              page: 1,
              order: 'asc',
            });
          }
        }}
        onChange={(v) => {
          if (v[0] && v[1]) {
            const d1 = moment(v[0]).unix();
            const d2 = moment(v[1]).unix();

            setSearchParamsAsObject({
              [item.field]: !d2 ? d1 : `${d1},${d2}`,
              page: 1,
              esc: 'asc',
            });
          }
        }}
      />
    </div>
  );
}

export default withTranslation()(Date);
