import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Card as CardWrapper, CardBody } from "reactstrap";

import Card from "./Card";

import chartLocales from "../utils/locales";
import { convertCents, formatCurrency } from "../../../helpers/format";
import PresetsFilter from "../../../Components/Common/PresetsFilter";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import useQsParams from "../../../Components/Hooks/QueryString";
import {
  getSalesCardsData,
  getSalesChartData,
} from "../../../slices/manager-panel/thunk";
import { getFilters } from "../../../Components/Common/Filter";
import { isEmpty } from "lodash";

const Sales = () => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();
  const [filtersParams, setFiltersParams] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [type, setType] = useState(searchParams.get("type"));

  const filtersAplicated = {
    totalRevenue: '/vendas?page=1&status=created,scheduled,expired,done',
    impactedRevenue: '/vendas?page=1&status=created,scheduled,expired,done&generated_cashback=001%2C100000000&used_cashback=000%2C000',
    incrementalBilling: '/vendas?page=1&status=created,scheduled,expired,done&used_cashback=001%2C100000000',
    billingWithoutCashback: '/vendas?page=1&status=created,scheduled,expired,done&used_cashback=000%2C000&generated_cashback=000%2C000'
  }

  const { sales } = useSelector(
    createSelector(
      (state) => state.ManagerPanel,
      ({ sales }) => ({ sales })
    )
  );

  useEffect(() => {
    getData();
  }, [dispatch, searchParams.toString()]);

  useEffect(() => {
    const totalData = sales?.chart?.data?.map((i) => {
      return (
        (type === "sales" ? i?.value1 : Math.round(convertCents(i?.value1))) +
        (type === "sales" ? i?.value2 : Math.round(convertCents(i?.value2))) +
        (type === "sales" ? i?.value3 : Math.round(convertCents(i?.value3)))
      );
    });

    setChartOptions({
      chart: {
        stacked: true,
        toolbar: { show: false },
        ...chartLocales,
      },
      legend: {
        onItemHover: { highlightDataSeries: true },
        onItemClick: { toggleDataSeries: true },
      },
      markers: { size: 5 },
      stroke: { width: [1, 1, 1, 2] },
      colors: ['#aaff00', '#18202a', '#ff6400', '#0e93c2'],
      labels: sales?.chart?.data?.map((i) => i?.date),
      xaxis: {
        labels: {
          formatter: (v) => v,
        },
      },
      yaxis: [
        {
          labels: {
            formatter: (v) => {
              return type === 'sales' ? v : formatCurrency(v, { convert: true });
            },
          },
          tickAmount: 4
        },
        {
          opposite: true,
          labels: {
            formatter: (v) => {
              return `${Math.round(v * 100)}%`;
            },
          },
          seriesName: "% Incremental",
        },
      ],
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
        },
        formatter: (v, { seriesIndex }) => {
          return seriesIndex === 1 ? `${Math.round(v * 100)}%` : type === 'sales' ? v : formatCurrency(v, { convert: true });
        },
        offsetY: -10,
        background: {
          enabled: true,
          foreColor: ['#000000', '#ffffff', '#000000', '#000000'],
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (v, { seriesIndex }) => {
            return seriesIndex === 1 ? `${Math.round(v * 100)}%` : type === 'sales' ? v : formatCurrency(v, { convert: true });
          },
        },
      },
    });
  }, [type, sales]);

  
  useEffect(() => {
    const newType = searchParams.get("type");
    setType(newType);
  }, [searchParams]);



  const filters = [
    {
      field: "created_at",
    },
    {
      field: "channel",
    },
    {
      field: "store_id",
    },
  ];

  const getData = () => {
    const type = searchParams.get("type");
    const params = type ? { type } : {};
    const appliedFilters = getFilters(filters, [
      { field: "created_at", type: "between_date" },
      { field: "channel", type: "in" },
      { field: "store_id", type: "in" },
    ]);

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    setFiltersParams(params.filters);
    dispatch(getSalesCardsData(params));
    dispatch(getSalesChartData(params));
  };


  return (
    <div>
      <h2 className="mb-3">Vendas</h2>

      <Row>
        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento Total"
              link={filtersAplicated.totalRevenue}
              data={sales?.cards?.total}
            />
          ) : (
            <div className="skeleton card" style={{ height: "144px" }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento Impactado"
              link={filtersAplicated.impactedRevenue}
              data={sales?.cards?.impacted}
            />
          ) : (
            <div className="skeleton card" style={{ height: "144px" }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento Incremental"
              link={filtersAplicated.incrementalBilling}
              data={sales?.cards?.incremental}
            />
          ) : (
            <div className="skeleton card" style={{ height: "144px" }} />
          )}
        </Col>

        <Col xs={12} md={6} xl={3}>
          {sales?.cards?.total?.length ? (
            <Card
              title="Faturamento sem Cashback"
              link={filtersAplicated.billingWithoutCashback}
              data={sales?.cards?.without_cashback}
            />
          ) : (
            <div className="skeleton card" style={{ height: "144px" }} />
          )}
        </Col>
      </Row>

      <div style={{ marginTop: 20, marginLeft: -15 }}>
        <PresetsFilter
          header={false}
          items={[
            {
              key: "fat",
              label: "Faturamento",
              value: { type: "billing" },
              isActive: type === null || type === "billing",
            },
            {
              key: "sales",
              label: "Vendas",
              value: { type: "sales" },
            },
            {
              key: "avg",
              label: "Ticket médio",
              value: { type: "ticket" },
            },
          ]}
        />
      </div>

      <Row>
        <Col>
          <CardWrapper>
            <CardBody>
              {sales?.chart?.data?.length ? (
                <ReactApexChart
                  key={type}
                  type="bar"
                  height={550}
                  options={chartOptions}
                  series={[
                    {
                      name: "Impactado",
                      type: "bar",
                      data: sales?.chart?.data?.map((i) => {
                        if (type === "sales") {
                          return i?.value1;
                        }
                        return Math.round(convertCents(i?.value1));
                      }),
                    },
                    {
                      name: "% Incremental",
                      type: "line",
                      data: sales?.chart?.data?.map((i) => i?.value4),
                    },
                    {
                      name: "Incremental",
                      type: "bar",
                      data: sales?.chart?.data?.map((i) => {
                        if (type === "sales") {
                          return i?.value2;
                        }
                        return Math.round(convertCents(i?.value2));
                      }),
                      yaxisIndex: 0,
                    },
                    {
                      name: "Sem cashback",
                      type: "bar",
                      data: sales?.chart?.data?.map((i) => {
                        if (type === "sales") {
                          return i?.value3;
                        }
                        return Math.round(convertCents(i?.value3));
                      }),
                      yaxisIndex: 0,
                    },
                  ]}
                />
              ) : (
                <div className="skeleton card" style={{ height: 550 }} />
              )}
            </CardBody>
          </CardWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Sales);
