import { createSlice } from '@reduxjs/toolkit';
import {
  getCommunicationRules,
  getCommunicationRulesByChannel,
  getCommunicationTemplate,
  getCommunicationTemplates,
  getTemplateVariables,
} from './thunk';

export const initialState = {
  loadingCount: 0,
  transactionals: {
    order: [],
    incentive: [],
    wallet: [],
  },
  rules: [],
  templates: [],
  template: {},
  variables: [],
  meta: {},
  error: null,
  isError: false,
};

const communication = createSlice({
  name: 'Communication',
  initialState,
  reducers: {
    cleanup: (state) => {
      state.template = {};
      state.rules = [];
      state.error = null;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunicationRules.pending, (state) => {
      state.loadingCount += 1;
      state.isError = false;
    });
    builder.addCase(getCommunicationRules.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.transactionals.wallet = action.payload.filter(tr => ['welcome'].includes(tr.transactional));
      state.transactionals.order = action.payload.filter(tr => ['purchase-made', 'credit-received', 'credit-to-expire', 'credit-to-receive'].includes(tr.transactional) && tr.type === 'order');
      state.transactionals.incentive = action.payload.filter(tr => ['credit-received', 'credit-to-expire'].includes(tr.transactional) && tr.type === 'incentive');
    });
    builder.addCase(getCommunicationRules.rejected, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.isError = true;
      state.error = action.error?.message || "Failed to fetch communication rules";
    });

    builder.addCase(getCommunicationRulesByChannel.pending, (state) => {
      state.loadingCount += 1;
      state.isError = false;
    });
    builder.addCase(getCommunicationRulesByChannel.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.rules = action.payload;
    });
    builder.addCase(getCommunicationRulesByChannel.rejected, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.isError = true;
      state.error = action.error?.message || "Failed to fetch rules by channel";
    });

    builder.addCase(getCommunicationTemplates.pending, (state) => {
      state.loadingCount += 1;
      state.isError = false;
    });
    builder.addCase(getCommunicationTemplates.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.templates = action.payload.data;
      state.meta = action.payload.meta;
    });
    builder.addCase(getCommunicationTemplates.rejected, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.isError = true;
      state.error = action.error?.message || "Failed to fetch communication templates";
    });

    builder.addCase(getCommunicationTemplate.pending, (state) => {
      state.loadingCount += 1;
      state.isError = false;
    });
    builder.addCase(getCommunicationTemplate.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.template = action.payload;
    });
    builder.addCase(getCommunicationTemplate.rejected, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.isError = true;
      state.error = action.error?.message || "Failed to fetch communication template";
    });

    builder.addCase(getTemplateVariables.pending, (state) => {
      state.loadingCount += 1;
      state.isError = false;
    });
    builder.addCase(getTemplateVariables.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.variables = action.payload;
    });
    builder.addCase(getTemplateVariables.rejected, (state, action) => {
      state.loadingCount -= 1;
      if (state.loadingCount === 0) {
        state.isLoading = false;
      }
      state.isError = true;
      state.error = action.error?.message || "Failed to fetch template variables";
    });
  },
});

export const { cleanup } = communication.actions;
export default communication.reducer;
