import * as moment from "moment";

export const formatDocument = (value) => {
  const cnpjCpf = value?.toString().replace(/\D/g, "");

  if (cnpjCpf?.length === 11) {
    return cnpjCpf
      ?.padStart(11, "0")
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return cnpjCpf
    ?.padEnd(14, "0")
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
};


export const formatDate = (date, format = "DD/MM/YYYY HH:mm") =>
  format === "DD/MM/YYYY 23:59"
  ? moment(date).endOf('day').format(`${format}`)
  : moment(date).format(format);

  
export const convertCents = (value) => value / 100;

export const convertToCents = (value) => value * 100;

export const formatCurrency = (value, opts) => {
  const style = opts?.style ?? "currency";
  const convert = !!opts?.convert;

  const formatNumber = new Intl.NumberFormat("pt-BR", {
    style,
    currency: "BRL",
    minimumFractionDigits: opts?.minimumFractionDigits ?? 2,
    maximumFractionDigits: opts?.maximumFractionDigits ?? 3,
  });

  const val = !convert ? convertCents(value) : value;
  return formatNumber.format(val);
};

export const formatInputCurrency = (value) => {
  let newValue = parseFloat(String(value).replace(/\D/g, "")) / 100;

  if (isNaN(newValue)) {
    newValue = 0;
  }

  const currencyFormat = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return currencyFormat.format(newValue).replace("R$", "");
};

export const formatCellphone = (cellphone) => {
  if (!cellphone) return "-";
  cellphone = cellphone?.replace("+55", "");
  return `(${cellphone.substring(0, 2)}) ${cellphone.charAt(2)} 
  ${cellphone.substring(3, 7)}-${cellphone.substring(7)}`;
};
