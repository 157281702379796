import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isStrategy, setIsStrategy] = useState(false);
  const [isManualOrder, setIsManualOrder] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isAccount, setIsAccount] = useState(false);
  const [isCommunication, setIsCommunication] = useState(false);
  const [isIncentives, setIsIncentives] = useState(false);
  const [isCatalog, setIsCatalog] = useState(false);
  const [isWallets, setIsWallets] = useState(false);
  const [isLeadCapture, setIsLeadCapture] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isCheckingAccount, setIsCheckingAccount] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (iscurrentState !== "Strategy") {
      setIsStrategy(false);
    }

    if (iscurrentState !== "ManualOrder") {
      setIsManualOrder(false);
    }

    if (iscurrentState !== "Account") {
      setIsAccount(false);
    }

    if (iscurrentState !== "Communication") {
      setIsCommunication(false);
    }

    if (iscurrentState !== "Catalog") {
      setIsCatalog(false);
    }

    if (iscurrentState !== "Reports") {
      setIsReport(false);
    }

    if (iscurrentState !== "CheckingAccount") {
      setIsCheckingAccount(false);
    }

    if (iscurrentState !== "Wallets") {
      setIsWallets(false);
    }

    if (iscurrentState !== "LeadCapture") {
      setIsLeadCapture(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isStrategy,
    isManualOrder,
    isAccount,
    isCommunication,
    isWallets,
    isLeadCapture,
  ]);

  const menuItems = [
    {
      label: "results",
      isHeader: true,
      permissions: ["one-page-report"],
    },
    {
      id: "one-page-report",
      label: "one-page-report",
      // label: "manager-panel.menu",
      icon: "bx bxs-dashboard",
      permissions: ["one_page_report", "all"],
      link: "/dashboard",
      click: function (e) {
        setIscurrentState("Dashboard");
      },
    },
    // {
    //   id: 'reports',
    //   label: 'Indicadores',
    //   icon: 'bx bx-line-chart',
    //   link: '/dashboard/reports',
    //   click: function (e) {
    //     setIscurrentState('Reports');
    //   },
    // },
    // {
    //   id: 'checking-account',
    //   label: 'Conta Corrente',
    //   icon: 'bx bx-money-withdraw',
    //   link: '/dashboard/conta-corrente',
    //   click: function (e) {
    //     setIscurrentState('CheckingAccount');
    //   },
    // },
    // {
    //     id: "dashboard",
    //     label: "results-management",
    //     icon: "bx bx-briefcase",
    //     link: "/#",
    //     click: function (e) {
    //         e.preventDefault();
    //         setIscurrentState('Dashboard');
    //     },
    // },
    // {
    //     id: "dashboard",
    //     label: "consumers-management",
    //     icon: "bx bxs-user-badge",
    //     link: "/#",
    //     click: function (e) {
    //         e.preventDefault();
    //         setIscurrentState('Dashboard');
    //     },
    // },
    {
      label: "management",
      isHeader: true,
      permissions: [
        "sales_management",
        "customers",
        "catalog_strategies",
        "account_strategies",
        "catalog",
      ],
    },
    {
      id: "sales_management",
      label: "orders",
      icon: "bx bx-cart",
      link: "/vendas",
      permissions: ["sales_management"],
      click: function (e) {
        setIscurrentState("Orders");
      },
    },

    {
      id: "customers",
      label: "wallets.menu",
      icon: "bx bx-wallet",
      link: "/carteiras",
      stateVariables: isWallets,
      permissions: ["customers", "incentives"],
      click: () => setIscurrentState("Wallets"),
      // subItems: [
      //   {
      //     id: "active-wallets",
      //     label: "active-wallets",
      //     link: "/carteiras",
      //     permission: "customers",
      //     parentId: "customers",
      //   },
      //   {
      //     id: "blocked-wallets",
      //     label: "blocked-wallets",
      //     link: "/carteiras/bloqueadas",
      //     permission: "customers",
      //     parentId: "customers",
      //   },
      // ],
    },

    {
      id: "strategies",
      label: "strategy",
      icon: "bx bx-git-merge",
      stateVariables: isStrategy,
      link: "/#",
      permissions: ["catalog_strategies", "account_strategies"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Strategy");
        setIsStrategy(!isStrategy);
      },
      subItems: [
        {
          id: "account",
          label: "account",
          link: "/estrategias/conta",
          permission: "account_strategies",
          parentId: "strategy",
        },
        {
          id: "catalog",
          label: "catalog",
          link: "/estrategias/catalogo",
          permission: "catalog_strategies",
          parentId: "strategy",
        },
      ],
    },
    // {
    //     id: "catalog",
    //     label: "catalog",
    //     icon: "bx bx-book-content",
    //     permissions: ['catalog'],
    //     link: "/#",
    //     click: function (e) {
    //         e.preventDefault();
    //         setIscurrentState('Catalog');
    //     },
    // },
    {
      label: "tools",
      isHeader: true,
      permissions: [
        "orders",
        "incentives",
        "smart_incentive",
        "communications",
        "lead_capture",
      ],
    },
    {
      id: "orders",
      label: "manual-order",
      icon: "bx bx-cart",
      link: "/#",
      permissions: ["orders"],
      stateVariables: isManualOrder,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("ManualOrder");
        setIsManualOrder(!isManualOrder);
      },
      subItems: [
        {
          id: "menu-new-order",
          label: "menu-new-order",
          link: "/vendas/novo",
          permission: "orders",
          parentId: "orders",
        },
      ],
    },

    {
      id: "incentives",
      label: "incentives",
      icon: "bx bx-gift",
      link: "/#",
      stateVariables: isIncentives,
      permissions: ["incentives", "smart_incentive"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Incentives");
        setIsIncentives(!isIncentives);
      },
      subItems: [
        {
          id: "manual",
          label: "manual-incentives",
          link: "/incentivos",
          permission: "incentives",
          parentId: "incentives",
        },
        {
          id: "smart",
          label: "smart-incentives.menu",
          link: "/incentivos-inteligentes",
          permission: "smart_incentive",
          parentId: "incentives",
        },
      ],
    },

    {
      id: "communications",
      label: "communication",
      icon: "bx bx-chat",
      link: "/#",
      stateVariables: isCommunication,
      permissions: ["communications"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Communication");
        setIsCommunication(!isCommunication);
      },
      subItems: [
        {
          id: "transactional",
          label: "transactional-rule",
          link: "/comunicacao/transacional",
          permission: "communications",
          parentId: "communications",
        },
        {
          id: "templates",
          label: "communication-templates.menu",
          link: "/comunicacao/templates",
          permission: "communications",
          parentId: "communications",
        },
      ],
    },
    {
      id: "catalog",
      label: "catalog",
      icon: "bx bx-purchase-tag",
      link: "/catalogo",
      stateVariables: isCatalog,
      permissions: ["catalogs"],
      click: function (e) {
        setIscurrentState("Catalog");
        setIsCatalog(!isCatalog);
      },
      subItems: [
        {
          id: "catalog-blocked",
          label: "catalog-blocked",
          link: "/catalogo/bloqueados",
          permission: "catalogs",
          parentId: "catalogs",
        },
      ],
    },
    {
      id: "lead-capture",
      label: "lead-capture.menu.parent",
      icon: "bx bx-magnet",
      link: "/#",
      stateVariables: isLeadCapture,
      permissions: ["lead_capture"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("LeadCapture");
        setIsLeadCapture(!isLeadCapture);
      },
      subItems: [
        {
          id: "lead-capture-dashboard",
          label: "lead-capture.menu.dashboard",
          link: "/captura-de-leads",
          permission: "lead_capture",
          parentId: "lead-capture",
        },
        {
          id: "lead-capture-settings",
          label: "lead-capture.menu.settings",
          link: "/captura-de-leads/configuracao",
          permission: "lead_capture",
          parentId: "lead-capture",
        },
      ],
    },
    {
      id: "my-wallet",
      label: "my-wallet.menu",
      icon: "bx bx-palette",
      link: "/minha-carteira",
      permissions: ["my_wallet"],
      click: function (e) {
        setIscurrentState("MyWallet");
      },
    },

    {
      label: "configurations",
      isHeader: true,
      permissions: ["account_users"],
    },
    {
      id: "account",
      label: "account",
      icon: "bx bx-building",
      link: "/#",
      permissions: ["account_users", "integrations"],
      stateVariables: isAccount,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Account");
        setIsAccount(!isAccount);
      },
      subItems: [
        {
          id: "account_users",
          label: "users",
          link: "/conta/usuarios",
          permission: "account_users",
          parentId: "account",
        },
        {
          id: "stores",
          label: "stores.menu",
          link: "/conta/lojas",
          permission: "stores",
          parentId: "account",
        },
        {
          id: "permissions",
          label: "permissions",
          link: "/conta/permissoes",
          permission: "account_users",
          parentId: "account",
        },
        {
          id: "general-settings",
          label: "general-settings.menu",
          link: "/conta/configuracoes-gerais",
          permission: "general_settings",
          parentId: "account",
        },
      ],
    },
    {
      id: "integrations",
      label: "integrations.menu",
      icon: "bx bx-cog",
      link: "/integracoes",
      permissions: ["integrations"],
      click: function () {
        setIscurrentState("Integrations");
      },
    },
    {
      label: "help",
      permissions: ["manual"],
      isHeader: true,
    },
    {
      id: "manual",
      label: "manual",
      icon: "bx bx-help-circle",
      link: "/#",
      permissions: ["manual"],
      click: function (e) {
        setIscurrentState("Manual");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
