import React, { useEffect, useReducer, useState } from 'react';
import * as Yup from 'yup';
import { Trans, withTranslation } from 'react-i18next';
import {
  Badge,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';

import { isEmpty, isNil } from 'lodash';
import { useFormik } from 'formik';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import Wizard from '../../../Components/Common/Wizard';
import CsvImporter from '../../../Components/Common/CsvImporter';
import { batchImportBlockedProducts } from '../../../slices/strategies/thunk';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import CurrencyInput from '../../../Components/Common/CurrencyInput';
import {
  batchImportIncentive,
  getAllIncentiveGroups,
} from '../../../slices/thunks';
import { currencyFieldToNumber } from '../../../helpers/functions';
import { formatDate } from '../../../helpers/format';

const initialValues = {
  cashback_amount: 0,
  cashback_expires_at: '',
  incentive_group_id: '',
  fields: undefined,
  rows: 0,
};

const IncentiveImporter = ({ isOpen, toggle, onSuccess, t, tReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [groups, setGroups] = useState([]);
  const dispatch = useDispatch();

  const getAllGroups = async () => {
    const action = await dispatch(getAllIncentiveGroups({
      filters: {
        type: { in: ['user'] },
      },
    }));

    if (!action.payload || action.error) {
      return;
    }

    setGroups(
      action.payload.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  const [payload, setPayload] = useReducer(
    (acc, item) => ({ ...acc, ...item }),
    initialValues
  );

  useEffect(() => {
    getAllGroups();
  }, [isOpen]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async () => {
      setIsLoading(true);

      const {
        columnFields,
        preview,
        cashback_amount,
        cashback_expires_at,
        incentive_group_id,
      } = payload;
      const configurationParse = columnFields.reduce(
        (currentvalue, value, index) => {
          const column = preview.columns.find((val) => val.index === index);

          return {
            ...currentvalue,
            [value || '']: { column: column?.header },
          };
        },
        {}
      );

      const expires_at = moment(payload.expires_at).toISOString();

      const data = {
        file: payload.file,
        cashback_amount: currencyFieldToNumber(cashback_amount),
        cashback_expires_at: moment(cashback_expires_at).toISOString(),
        incentive_group_id,
        configuration_parse: JSON.stringify(configurationParse),
      };

      const action = await dispatch(
        batchImportIncentive({
          ...data,
          successMessage: t('import-success'),
          errorMessage: t('import-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        resetState();
        onSuccess();
      }
    },
  });

  const resetState = () => {
    setTimeout(() => {
      setActiveTab(1);
      setPayload(initialValues);
      formik.resetForm();
    }, 1000);
    toggle();
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        size={activeTab >= 2 ? 'lg' : 'xl'}
        style={{ transition: 'all 0.2s ease' }}
        toggle={resetState}
        id="showModal"
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {tReady && t('incentive-import-modal-heading')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Wizard
              showSteps
              activeTab={activeTab}
              tabs={[
                {
                  label: tReady && t('incentive-import-modal-step1-heading'),
                  component: (
                    <>
                      <h6>
                        {tReady && t('incentive-import-modal-step1-heading')}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
                          t('incentive-import-modal-step1-description')}
                      </p>

                      <CsvImporter
                        onClose={() => setActiveTab(2)}
                        onComplete={(data) => setPayload({ ...data })}
                        dataHandler={(raw) => {
                          const rows = raw?.reduce(
                            (acc, i) => acc + i,
                            payload.rows
                          );
                          setPayload({ rows });
                        }}
                        fields={[
                          {
                            name: 'document',
                            label: t('import-document-prop'),
                          },
                          {
                            name: 'name',
                            label: t('import-name-prop'),
                            optional: true,
                          },
                          {
                            name: 'email',
                            label: t('import-email-prop'),
                            optional: true,
                          },
                          {
                            name: 'birthdate',
                            label: t('import-birthdate-prop'),
                            optional: true,
                          },
                          {
                            name: 'cellphone',
                            label: t('import-cellphone-prop'),
                            optional: true,
                          },
                        ]}
                      />
                    </>
                  ),
                },

                {
                  label: tReady && t('incentive-import-modal-step2-heading'),
                  component: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h6>
                        {tReady && t('incentive-import-modal-step2-heading')}
                      </h6>
                      <span>
                        {tReady &&
                          t('incentive-import-modal-step2-description')}
                      </span>

                      <div className="gap-2 mt-4 mb-2 w-50 justify-content-start">
                        <Label className="form-label" htmlFor="incentive-group">
                          {t('incentive-import-cashback-incentive-group')}{' '}
                          <span className="text-danger">*</span>
                        </Label>

                        <Select
                          name="incentive-group"
                          className="mb-3"
                          onChange={({ value }) => {
                            formik.setFieldValue({ incentive_group_id: value });
                            setPayload({
                              ...payload,
                              incentive_group_id: value,
                            });
                          }}
                          value={formik.values.incentive_group}
                          placeholder={t('select-placeholder')}
                          options={groups}
                        />

                        <Label className="form-label" htmlFor="cashback-amount">
                          {t('incentive-import-cashback-amount')}{' '}
                          <span className="text-danger">*</span>
                        </Label>

                        <CurrencyInput
                          name="cashback-amount"
                          className="mb-3"
                          placeholder="0,00"
                          onChange={(value) => {
                            formik.setFieldValue('cashback_amount', value);
                            setPayload({ ...payload, cashback_amount: value });
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.cashback_amount}
                          // invalid={isInvalid(formik, 'cashback_amount')}
                        />

                        <Label
                          className="form-label"
                          htmlFor="cashback-days-to-expire"
                        >
                          {t('incentive-import-cashback-expires-at')}{' '}
                          <span className="text-danger">*</span>
                        </Label>

                        <Flatpickr
                          className="form-control"
                          id="expires-at-field"
                          placeholder={t('select-date')}
                          value={formik.values.cashback_expires_at}
                          onChange={(e) => {
                            formik.setFieldValue('cashback_expires_at', e[0]);
                            setPayload({
                              ...payload,
                              cashback_expires_at: e[0],
                            });
                          }}
                          options={{
                            altInput: true,
                            altFormat: 'd/m/Y',
                            dateFormat: 'd.m.y',
                          }}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-success align-right"
                        style={{ alignSelf: 'end' }}
                        disabled={
                          isNil(payload?.incentive_group_id) ||
                          (payload?.cashback_amount &&
                            currencyFieldToNumber(payload?.cashback_amount) <=
                              0) ||
                          isNil(payload?.cashback_expires_at)
                        }
                        onClick={() => setActiveTab(3)}
                      >
                        {t('confirm')}
                      </button>
                    </div>
                  ),
                },

                {
                  label: tReady && t('incentive-import-modal-step3-heading'),
                  component: (
                    <>
                      <h6>
                        {tReady && t('incentive-import-modal-step3-heading')}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
                          t('incentive-import-modal-step3-description')}
                      </p>

                      <Table bordered striped>
                        <tbody>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('incentive-import-review-amount')}
                            </th>
                            <td>
                              {payload?.rows} {t('items')}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('incentive-import-review-cashback-amount')}
                            </th>
                            <td>{payload?.cashback_amount}</td>
                          </tr>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('incentive-import-review-cashback-expires-at')}
                            </th>
                            <td>
                            {payload?.cashback_expires_at && formatDate(payload?.cashback_expires_at, "DD/MM/YYYY 23:59")}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t(
                                'incentive-import-review-cashback-incentive-group'
                              )}
                            </th>
                            <td>
                              {payload?.incentive_group_id && (
                                <>
                                  {
                                    groups.find(
                                      (group) =>
                                        group.value ===
                                        payload.incentive_group_id
                                    )?.label
                                  }
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={resetState}
                          disabled={isLoading}
                        >
                          {t('close')}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isLoading}
                        >
                          {t('confirm')}
                        </button>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(IncentiveImporter);
