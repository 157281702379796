import axios from "axios";
import { stringify } from "qs";

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

const setAuthHeader = () => {
  const authUser = JSON.parse(sessionStorage.getItem("authUser"))
  const token = authUser ? authUser.access_token : null;
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

setAuthHeader()

const setStoresHeaders = () => {
  const storesFilter = sessionStorage.getItem("filterByStores") ?? null;
  axios.defaults.headers.common["x-store-ids"] = storesFilter;
};

export function cleanupStoreHeaders() {
  setTimeout(() => {
    sessionStorage.removeItem("filterByStores");
    axios.defaults.headers.common["x-store-ids"] = null;
  }, 1000);
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    console.log(error);
    if (error?.response?.status === 401) {
      try {
        const originalRequest = error.config;

        if (
          error.response?.status === 401 &&
          error?.response?.data?.id !== 'invalid-email-or-password'
          && !originalRequest?._retry
        ) {
          originalRequest._retry = true;

          const response = await refreshToken()

          if (!response) {
            Promise.reject()
          }

          if (response) {
            sessionStorage.setItem('authUser', JSON.stringify(response))
            setAuthorization(response.access_token);

            originalRequest.headers.Authorization = `Bearer ${response.access_token}`;
            return axios(originalRequest);
          }
        }
      } catch (e) {
        sessionStorage.removeItem('authUser');
        sessionStorage.removeItem("filterByStores");
        window.location.href = '/login';
      }
    }

    let message;
    switch (error.response.status) {
      case 500:
        message = "server-error";
        break;
      case 401:
        message = error?.response?.data?.id === 'invalid-email-or-password' ? "Email e/ou senha inválidos" : 'unauthorized';
        break;
      case 404:
        message = "not-found";
        break;
      case 400:
        message = "bad-request";
        break;
      case 409:
        message = "conflict";
        break;
      case 422:
        message = error?.response?.data || error.message;
        break;
      default:
        message = error.message || error;
    }

    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    setAuthHeader()
    setStoresHeaders();
    let response;

    if (params) {
      const queryString = stringify(params);
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    cleanupStoreHeaders();
    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data, config) => {
    setStoresHeaders();
    return axios.post(url, data, { ...config });
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    setStoresHeaders();
    return axios.patch(url, data)
      .then(response => {
        return response; 
      })
      .catch(error => {
        throw error; 
      });
  };

  put = (url, data) => {
    setStoresHeaders();
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    setStoresHeaders();
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  }

  return JSON.parse(user);
};

const refreshToken = () => {
  const user = JSON.parse(sessionStorage.getItem('authUser'));

  if (!user) {
    return null;
  }

  return axios.post('/session/token', {
    type: 'refresh',
    token: user.refresh_token
  });
}

export { APIClient, setAuthorization, getLoggedinUser };