import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Col, Container, Row, Button } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import InformativeCard from '../../Components/Common/InformativeCard';
import { useDispatch } from 'react-redux';
import { simulateOrder, updateOrder } from '../../slices/orders/thunk';
import FirstTab from './FirstTab';
import { first, isEmpty, isNil } from 'lodash';
import SecondTab from './SecondTab';
import ThirdTab from './ThirdTab';
import { Can } from '../../helpers/casl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelModal from './CancelModal';
import Wizard from '../../Components/Common/Wizard';
import { useFormik } from 'formik';

const NewOrder = (props) => {
  document.title = 'Nova venda digitada | Opencashback';

  const dispatch = useDispatch();
  const { t } = props;

  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState({});
  const [wallet, setWallet] = useState({});
  const [order, setOrder] = useState({});
  const [rescueBalance, setRescueBalance] = useState(0);
  const [resume, setResume] = useState({});
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [resetFirstStep, setResetFirstStep] = useState(0);
  const [resetSecondStep, setResetSecondStep] = useState(0);
  const [simulateOrderIsLoading, setSimulateOrderIsLoading] = useState(false);

  useEffect(() => {
    const handleOrderCancel = (e) => {
      if (e.key === 'Escape') {
        setIsOpenCancelModal(true);
      }
    };

    if (step === 2) {
      document.addEventListener('keydown', handleOrderCancel);

      return () => {
        document.removeEventListener('keydown', handleOrderCancel);
      };
    }
  }, [step]);

  useEffect(() => {
    if (
      !isNil(order) &&
      !isEmpty(order) &&
      !isNil(customer) &&
      !isEmpty(customer) &&
      rescueBalance <= (order?.cashback?.maximum_rescue || 0)
    ) {
      setSimulateOrderIsLoading(true);
      simulateOrderAndSetOrder(order.total, customer.document, rescueBalance);
    }
  }, [rescueBalance]);

  const toggleCancelModal = () => {
    setIsOpenCancelModal(!isOpenCancelModal);
  };

  const toNumber = (value) => {
    return Number(String(value).replaceAll('.', '').replace(',', ''));
  };

  const onSubmitFirstStep = async (value) => {
    setCustomer(value.customer);
    setWallet(first(value.customer.wallets));

    const orderSimulation = await simulateOrderAndSetOrder(
      value.total,
      value.customer.document,
      value.cashback?.maximum_rescue
    );

    setRescueBalance(orderSimulation.cashback?.maximum_rescue || 0);

    setStep(2);
  };

  const simulateOrderAndSetOrder = async (
    total,
    customerDocument,
    totalPaid
  ) => {
    const orderData = await simulate(total, customerDocument, totalPaid);
    setOrder(orderData);
    setSimulateOrderIsLoading(false);

    return orderData;
  };

  const onSubmitSecondStep = (value) => {
    setResume({
      customer,
      order: value,
      rescue: value.used_cashback,
      percentage: parseFloat((value.used_cashback / value.total) * 100).toFixed(
        2
      ),
    });
    setStep(3);
  };

  const clearForm = () => {
    setResetFirstStep(resetFirstStep + 1);
    setResetSecondStep(resetSecondStep + 1);
    setCustomer({});
    setWallet({});
    setOrder({});
    setResume({});
    setStep(1);
  };

  const onCancel = () => {
    setIsOpenCancelModal(false);
    clearForm();
  };

  const updateOrderFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      store: {
        label: resume.order?.store,
        value: resume.order?.store_id,
      },
      seller: resume.order?.seller,
    },
    onSubmit: async (values) => {
      const order = {
        ...(values?.ticket && {
          external_id: values.ticket,
        }),
        ...(values?.store?.value && {
          store: values?.store?.label,
          store_id: values?.store?.value,
        }),
      };

      const action = await dispatch(
        updateOrder({
          external_id: resume?.order?.external_id,
          order,
          successMessage: t('sale-completed'),
          errorMessage: t('sale-edit-error'),
          conflictMessage: t('sale-edit-conflict-error'),
        })
      );

      if (!action.payload?.error) {
        updateOrderFormik.resetForm();
        clearForm();
      }
    },
  });

  const simulate = async (total, document, cashbackRedeemed) => {
    total = toNumber(total);
    const totalPaid = cashbackRedeemed
      ? total - toNumber(cashbackRedeemed)
      : total;
    const data = await dispatch(
      simulateOrder({
        document,
        total,
        total_paid: totalPaid,
      })
    );

    if (data?.payload?.error) {
      return {};
    }

    return data?.payload;
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title={t('manual-order-title')} pageTitle={t('tools')} />
          <Can I='create' a='orders'>
            <Row className='justify-content-center mt-5 aligm-items-center'>
              <Col lg={12} xl={9} xxl={9}>
                <Card>
                  <Wizard
                    showSteps
                    activeTab={step}
                    tabs={[
                      {
                        label: t('first-step-manual-order-title'),
                        component: (
                          <>
                            {' '}
                            <FirstTab
                              onSubmit={onSubmitFirstStep}
                              reset={resetFirstStep}
                            />
                          </>
                        ),
                      },
                      {
                        label: t('second-step-manual-order-title'),
                        component: (
                          <>
                            <SecondTab
                              customer={customer}
                              wallet={wallet}
                              order={order}
                              onSubmit={onSubmitSecondStep}
                              reset={resetSecondStep}
                              setRescueBalance={setRescueBalance}
                              rescueBalance={rescueBalance}
                              simulateOrderIsLoading={simulateOrderIsLoading}
                            />
                          </>
                        ),
                      },
                      {
                        label: t('third-step-manual-order-title'),
                        component: (
                          <>
                            <ThirdTab
                              updateOrderForm={updateOrderFormik}
                              resume={resume}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </Card>
                {step === 3 && (
                  <Row className='justify-content-center align-items-center text-center'>
                    <Col>
                      <Button
                        onClick={updateOrderFormik.handleSubmit}
                        className='btn btn-primary btn-label right ms-auto nexttab nexttab fs-14 fw-semibold'
                        size='lg'
                      >
                        {t('new-order')}
                        <i className='ri-check-line label-icon align-middle ms-2'></i>
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>

              {step === 2 && (
                <Col xxl={3} xl={3}>
                  <InformativeCard
                    label={props.t('accumulation-amount')}
                    prefix='R$'
                    suffix=''
                    separator=','
                    counter={
                      order && order.cashback && order.cashback.total / 100
                    }
                    decimals={2}
                    duration={4}
                    bgcolor='secondary'
                    icon='bx bx-wallet'
                  />
                  <InformativeCard
                    label={props.t('rescue-limit')}
                    prefix='R$'
                    suffix=''
                    separator=','
                    counter={
                      order &&
                      order.cashback &&
                      order.cashback.maximum_rescue / 100
                    }
                    decimals={2}
                    duration={4}
                    bgcolor='secondary'
                    icon='bx bx-wallet'
                  />
                </Col>
              )}
            </Row>
          </Can>
        </Container>
      </div>
      <ToastContainer position='top-center' closeButton={false} limit={1} />
      <CancelModal
        isOpen={isOpenCancelModal}
        toggle={toggleCancelModal}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};

export default withTranslation()(NewOrder);
