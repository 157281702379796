import { withTranslation } from "react-i18next";
import { Label, Row } from "reactstrap";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import { formatCurrency, formatInputCurrency } from "../../../helpers/format";
import useQsParams from "../../Hooks/QueryString";
import CurrencyInput from "../CurrencyInput";

const toLog = (value) => Math.log(value + 1); 
const fromLog = (value) => Math.exp(value) - 1;

function RangeFilter({ item, orderFilters, t }) {
  const { qs, setSearchParamsAsObject } = useQsParams();

  const prefix = item?.field ?? "";
  const nameFrom = `${prefix}from`;
  const nameTo = `${prefix}to`;

  let range = orderFilters?.length
    ? orderFilters.find((f) => f?.field === item.field)?.values?.map(Number) || [1, 10000000]
    : [1, 1000000];

  range = range.map((value) => Math.max(0, Number(value) || 0)); 
  if (range[0] >= range[1]) {
    range[1] = range[0] + 1; 
  }

  console.log(range);

  const raw = qs.get(item.field)?.split(",") || [];
  const fromInput = raw.length > 0 ? Math.max(Number(raw[0]), 0) : range[0]; 
  const toInput = raw.length > 1 ? Math.max(Number(raw[1]), 0) : range[1]; 

  const from = Math.max(Math.min(fromInput, range[1]), range[0]);
  const to = Math.max(Math.min(toInput, range[1]), range[0]);

  const handleSliderChange = ([nFrom, nTo]) => {
    const v1 = Math.round(fromLog(nFrom));
    const v2 = Math.round(fromLog(nTo));

    const validFrom = Math.max(Math.min(Math.max(v1, 0), range[1]), range[0]); 
    const validTo = Math.max(Math.min(Math.max(v2, 0), range[1]), range[0]); 

    setSearchParamsAsObject({
      [item.field]: `${validFrom},${validTo}`,
      page: 1,
    });
  };

  const handleInputChange = (side, value) => {
    const parsedValue = Math.max(Number(String(value).replace(/\D/g, "")) || 0, 0); 

    const newFrom = side === "from" ? Math.max(Math.min(parsedValue, range[1]), range[0]) : from;
    const newTo = side === "to" ? Math.max(Math.min(parsedValue, range[1]), range[0]) : to;

    setSearchParamsAsObject({
      [item.field]: `${newFrom},${newTo}`,
      page: 1,
    });
  };

  return (
    <div className='mb-4 filter-item'>
      <Label className='form-label text-muted text-uppercase fw-semibold mb-3 d-flex justify-content-between'>
        <span>{item.name}</span>

        {qs.get(item.field)?.length ? (
          <span
            className='fw-normal'
            onClick={() => setSearchParamsAsObject({ [item.field]: "", page: 1 })}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: 11,
            }}
          >
            {t("clear")}
          </span>
        ) : null}
      </Label>

      <div className='mb-4 px-1'>
        <Nouislider
          connect
          step={0.1} 
          id={`range-${item.field}-slider`}
          range={{ min: toLog(range[0]), max: toLog(range[1]) }}
          start={[toLog(from), toLog(to)]}
          tooltips={[
            { to: (v) => formatCurrency(Math.round(fromLog(v))) },
            { to: (v) => formatCurrency(Math.round(fromLog(v))) },
          ]}
          onChange={handleSliderChange}
        />
      </div>

      <Row className='g-2 align-items-center'>
        <div className='input-group col-lg'>
          <span className='input-group-text'>{t("currency")}</span>
          <CurrencyInput
            name={nameFrom}
            placeholder='0,00'
            value={formatInputCurrency(from)}
            onChange={(value) => handleInputChange("from", value)} 
          />
        </div>

        <div className='col-lg-auto'>{t("to")}</div>

        <div className='input-group col-lg'>
          <span className='input-group-text'>{t("currency")}</span>
          <CurrencyInput
            name={nameTo}
            placeholder='0,00'
            value={formatInputCurrency(to)}
            onChange={(value) => handleInputChange("to", value)} 
          />
        </div>
      </Row>
    </div>
  );
}

export default withTranslation()(RangeFilter);
